import coreToggle from '@nrk/core-toggle'
import { nrkUserLoggedin } from '@nrk/core-icons'
import { connect } from '@nrk/nrkno-login-connector'
import { initializeCookieBanner, initializeLoginBanner } from './nrkno-masthead-banners'

export function initialize() {
  if (typeof document === 'undefined') return
  registerMBLScript()

  coreToggle('.nrk-masthead__bars, .nrk-masthead__more', { popup: true })

  const loginLinks = document.querySelectorAll('.nrk-masthead__user a')
  const loginLinksArray = Array.from(loginLinks)

  const cookieInfoCookieName = 'nrkno-cookie-information'
  const darkmodeCookieName = 'nrkno-darkmode'
  const allCookies = document.cookie.split(';')

  const isApp = window.location.search.indexOf('header=off') > 0
  let showCookieBanner = isApp ? false : true
  allCookies.forEach((cookie) => {
    if (cookie.trim().substring(0, cookieInfoCookieName.length) === cookieInfoCookieName) {
      showCookieBanner = false
    }
    if (cookie.trim().substring(0, darkmodeCookieName.length) === darkmodeCookieName) {
      handleDarkmodeCookie(cookie)
    }
  })

  if (loginLinksArray && loginLinksArray.length > 0) {
    connect((user) => {
      if (user) {
        loginLinksArray.forEach((loginLink) => {
          loginLink.href = 'https://www.nrk.no/minside'
          const span = document.createElement('span')
          span.textContent = user.name
          loginLink.innerHTML = nrkUserLoggedin
          loginLink.appendChild(span)
        })
        const isAutoLogin = window.location.search.indexOf('autoLogin=true') >= 0

        if (isAutoLogin) {
          initializeLoginBanner(window, user.name, showCookieBanner)
        }
      }
      loginLinksArray.forEach((loginLink) => {
        loginLink.removeAttribute('hidden')
      })
    })
  }

  if (showCookieBanner) {
    initializeCookieBanner(cookieInfoCookieName)
  }

  handleJumpToContent()
}
/**
 * Find, if any, the disabled jumpAnchor and "enable" it.
 */
function handleJumpToContent() {
  const jumpAnchor = document.querySelector('#nrk-masthead__jump-anchor[class~="nrk-masthead__jump-anchor--disabled"]')

  if (!jumpAnchor) {
    return
  }

  const mainElement = getMainElement()
  if (!mainElement) {
    // retry incase dom not loaded yet
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', handleJumpToContent)
    }
    return
  }

  if (!mainElement.id) {
    mainElement.id = 'innhold'
  }

  jumpAnchor.setAttribute('href', `#${mainElement.id}`)
  jumpAnchor.classList.remove('nrk-masthead__jump-anchor--disabled')
  jumpAnchor.classList.add('nrk-masthead__jump-anchor--enabled')
  // no-op if listener has not been added
  document.removeEventListener('DOMContentLoaded', handleJumpToContent)
}

/**
 * @returns {Element | undefined} the `main` element or the _best-effort_ main element.
 */
function getMainElement() {
  const mainElement = document.querySelector('main, [role="main"], #innhold')

  if (mainElement) {
    return mainElement
  }

  const bestEffortMainElement = document.querySelector('nrkno-player, article a, a > figure')

  if (!bestEffortMainElement) {
    return
  }

  if (bestEffortMainElement.tagName === 'FIGURE') {
    // return the parent/`a` tag
    return bestEffortMainElement.parentElement
  }

  return bestEffortMainElement
}

// Appends MBL Script to <head> so that page views are tracked
function registerMBLScript() {
  const mblScript = document.createElement('script')
  mblScript.type = 'text/javascript'
  mblScript.src = 'https://log.medietall.no/analytics.js'
  mblScript.async = true

  document.head.appendChild(mblScript)
}

// Check for darkmode cookie and set correct html-class
function handleDarkmodeCookie (cookie) {
  const value = cookie.substring(cookie.indexOf('=') + 1)
  // If darkmode cookie is "on", or if it's "auto" and the use has darkmode preference set in the OS/browser
  const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  if (value && (value === 'on' || (value === 'auto' && prefersDarkMode))) {
    if (!document.documentElement.className.includes('darkmode')) {
      document.documentElement.classList.add('darkmode')
    }
    // NTB Livesport sets darkmode differently:
    const dataTheme = document.documentElement.getAttribute('data-theme')
    if (dataTheme && dataTheme === 'light') {
      document.documentElement.setAttribute('data-theme', 'dark')
      document.documentElement.style.colorScheme = 'dark'
    }
  }
}
